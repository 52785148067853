<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="#1976d2" size="128" is-full-screen />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pt-5>
      <v-flex xs12 sm12 md12 lg12 py-3 px-5 style="background-color: #00A08E33;">
        <v-layout wrap justify-space-between align-center>
          <v-flex xs6 sm6 md6 lg11>
            <span style="font-family: Montserrat-Bold; font-size: 26px;">{{ subView.name }}</span>
          </v-flex>

          <v-flex lg1>
            <v-layout wrap justify-center>

            
          <v-flex xs3 sm3 md3 lg6 class="text-right">
            <v-btn icon @click="subscriptionEditPass()">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs3 sm3 md3 lg6 class="text-right">
            <v-btn icon @click="deleteSub = true">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-flex>

        </v-layout>
      </v-flex>


        </v-layout>
      </v-flex>

      <v-flex xs12 sm12 md12 lg12 px-5>
        <v-layout wrap justify-start pb-8>
          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Subscription Type
                  :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isFree">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Free</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Paid</span>
              </v-flex>
            </v-layout>

          </v-flex>


          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Advertisments :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isAdsIncluded">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Enabled</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Disable</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Accessibility :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isLimitedAccess">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Limited</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Full</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">File Upload Limit
                  :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isFileUploadLimited">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Enabled</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Disable</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8 v-if="subView.isFileUploadLimited">
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Upload Limit :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{ subView.fileUploadLimit }}
                  MB</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Trial Period :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isLimitedAccess">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{ subView.trialPeriod }} &nbsp;Days</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{ subView.trialPeriod }} &nbsp;Days</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Description :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{ subView.description }}</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">File Export
                  :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isFileExportAvailable">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Enabled</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Disable</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">File Register
                  :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.isFileRegisterAvailable">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Enabled</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">Disable</span>
              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex xs12 sm6 md4 lg4 pt-8>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Priority
                  :</span>
              </v-flex>
              <v-flex xs6 sm8 md6 lg4 v-if="subView.planPriority">
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{subView.planPriority}}</span>
              </v-flex>

              <v-flex xs6 sm8 md6 lg4 v-else>
                <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">N/A</span>
              </v-flex>
            </v-layout>

          </v-flex>


          <v-flex xs12 sm6 md4 lg4 pt-8 v-if="features.length > 0">
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 md6 lg5>
                <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Features :</span>
              </v-flex>
          <v-flex xs6 sm8 md6 lg4 v-if="subView">
            <v-layout wrap justify-center>
  <v-flex v-for="(feature, index) in features" :key="index" xs12 sm12 md12 lg12 pb-2>
    <v-layout style="font-family: Lato-Regular; font-weight: 600; font-size: 16px" align-center>
      <v-icon small>mdi-check</v-icon>
      <span>&nbsp;&nbsp;{{ feature }}</span>
    </v-layout>
  </v-flex>
</v-layout>


      </v-flex>
      </v-layout>
      </v-flex>

        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center>
      <v-flex lg12 v-if="subView.isFree === false">

      

    <v-layout wrap justify-center pt-5>
      <v-flex xs12 sm12 md12 lg6 py-3 px-5>
        <span style="font-family: Montserrat-Bold; font-size: 24px;">Subscription Plan Types</span>
      </v-flex>
      <v-flex xs12 sm12 md12 lg6 py-3 px-5 class="text-end">
        <v-btn color="#00A08E" @click="addPlan = true"><span style="font-family: Montserrat-Regular; font-weight: 600; color: white">Add Plan Type</span></v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start px-5 v-if="planType.length > 0">
      <v-flex lg3 v-for="(item, index) in planType" :key="index" pb-6>
        <v-card class="custom-card-size" @click="planIdPass(item._id,item.subscriptionPlanId)" :class="{ 'dull-card': item.status === 'Inactive' }" >
          <v-layout wrap justify-center>
            <v-flex lg12 pa-5 pb-2>
              <v-layout wrap justify-center>
                <v-flex xs11 sm11 md11 lg11>
                  <span style="font-family: Montserrat-Regular; font-size: 16px; font-weight: 600;">{{ item.name }}</span>
                </v-flex>
                <v-flex xs1 sm1 md1 lg1>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list height="48px" class="pa-0">
                    <v-list-item @click="planTypeEdit(item._id, item.name, item.duration, item.status, item.isBulkSubscriptionAvailable, item.isBulkAccountsLimited, item.maxAccountLimit)">
                      <v-list-item-title>
                        <span style="font-family: Lato-Regular; font-weight: 400">
                          <v-icon color="black">mdi-pencil</v-icon>&nbsp; Edit
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list height="48px" class="pa-0">
                    <v-list-item @click="deleteSubscriptionPlan(item._id)">
                      <v-list-item-title>
                        <span style="font-family: Lato-Regular; font-weight: 400">
                          <v-icon color="black">mdi-delete</v-icon>&nbsp; Delete
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </v-flex>

                <v-flex xs12 sm12 md12 lg12 pt-2>
                  <span>Duration : {{ item.duration }}</span>
                </v-flex>

                <v-flex xs9 sm9 md9 lg9>
                  <span>Status : {{ item.status }}</span>
                </v-flex>

                <v-flex xs3 sm3 md3 lg3>
                  <v-btn small style="background-color: #00A08E" v-if="item.isBulkSubscriptionAvailable"><span style="color: white;">Bulk</span></v-btn>
                </v-flex>
<!-- 
                <v-flex lg12>
                  <span>Bulk Subscription : {{ item.isBulkSubscriptionAvailable ? 'Available':'NO' }}</span>
                </v-flex>

                <v-flex lg12>
                  <span>Accounts Limited : {{ item.isBulkAccountsLimited ? 'Yes' : 'No' }}</span>
                </v-flex>

                <v-flex lg12>
                  <span>Max Account Limit : {{ item.maxAccountLimit }}</span>
                </v-flex> -->

              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-else pa-4>
      <v-flex lg2>
        <v-img src="../../../assets/images/nodata.png">

        </v-img>

        <v-flex lg12 pt-3 class="text-center">
          <span>No Plan Types !!</span>
        </v-flex>
      </v-flex>
    </v-layout>

<v-layout wrap justify-center  v-if="isPricing">
  <v-flex lg12>

 
    <v-layout wrap justify-center pt-5>
      <v-flex xs12 sm12 md12 lg6 py-3 px-5 align-self-center>
        <span style="font-family: Montserrat-Bold; font-size: 24px;">Pricing Plan</span>
      </v-flex>
      <v-flex xs12 sm12 md12 lg6 py-3 px-5 class="text-end">
        <v-btn color="#00A08E" @click="addPricing = true"><span style="font-family: Montserrat-Regular; font-weight: 600; color: white">Add Pricing</span></v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pb-10 v-if="pricing.length > 0">
      <v-flex lg3 v-for="(item, index) in pricing" :key="index" class="px-5 pb-6">
        <v-card class="custom-card-size">
          <v-layout wrap justify-center>
            <v-flex lg12 pa-5>
              <v-layout wrap justify-center>
                <v-flex xs11 sm11 md11 lg11>
                  <span style="font-family: Montserrat-Regular; font-size: 16px; font-weight: 600;">{{ item.countryId.name }}</span>
                </v-flex>
                <v-flex xs1 sm1 md1 lg1>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list height="48px" class="pa-0">
                    <v-list-item @click="pricingEdit(item._id, item.amount, item.originalAmount, item.isDefault, item.status)">
                      <v-list-item-title>
                        <span style="font-family: Lato-Regular; font-weight: 400">
                          <v-icon color="black">mdi-pencil</v-icon>&nbsp; Edit
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list height="48px" class="pa-0">
                    <v-list-item @click="deleteSubscriptionPricing(item._id)">
                      <v-list-item-title>
                        <span style="font-family: Lato-Regular; font-weight: 400">
                          <v-icon color="black">mdi-delete</v-icon>&nbsp; Delete
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </v-flex>

                <v-flex xs12 sm12 md12 lg12 pt-2>
                  <span style="font-family: Montserrat-Regular; font-size: 16px; font-weight: 500; color: #787878	;"><strike>{{ item.countryId.currencySymbol }} {{ item.originalAmount }}</strike></span>&nbsp;&nbsp;
                  <span style="font-family: Montserrat-Regular; font-size: 16px; font-weight: 500; color: green;">{{ item.discountPercentage }}% off</span>
                </v-flex>

                <v-flex xs12 sm12 md12 lg12 pt-2>
                  <span style="font-family: Montserrat-Regular; font-size: 20px; font-weight: 600;">{{ item.countryId.currencySymbol }} {{ item.amount }}</span>
                </v-flex>

                <!-- <v-flex lg3>
                  <v-btn small style="background-color: #00A08E" v-if="item.discountPercentage"><span style="color: white;">Default Price</span></v-btn>
                </v-flex> -->

                <v-flex xs12 sm12 md12 lg12 class="text-end">
                  <v-btn small style="background-color: #00A08E; cursor: default;" v-if="item.isDefault"><span style="color: white;">Default Price</span></v-btn>
                </v-flex>

              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center pb-10 v-else>
      <v-flex lg2>
        <v-img src="../../../assets/images/nodata.png"></v-img>
      </v-flex>
      <v-flex xs12 sm12 md12 lg12 pt-3 class="text-center"> 
        <span>No Pricing Plans !!</span>
      </v-flex>
    </v-layout>

  </v-flex>
</v-layout>

</v-flex>
</v-layout>


    <v-dialog v-model="editSubDialog" :width="$vuetify.breakpoint.name == 'xs' ? '90%' : $vuetify.breakpoint.name == 'sm' ? '50%' : $vuetify.breakpoint.name == 'md' ? '55%' : $vuetify.breakpoint.name == 'lg' ? '40%' : '40%'" content-class="dialog-custom">
          <v-card>
            <v-layout wrap justify-center pa-6>
              <v-flex xs12 sm12 md12 lg12>
                <v-layout wrap justify-center>
                  <v-flex xs11 sm11 md11 lg11 pb-3>
                    <span
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 24px;
                        font-weight: 700;
                      "
                      >Edit Subscription</span
                    >
                  </v-flex>
                  <v-flex xs1 sm1 md1 lg1>
                    <v-btn icon @click="editSubDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-flex>

                  <v-flex xs12 sm12 md12 lg12>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md6 lg6 pa-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Name</span
                        >
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          placeholder="Name"
                          v-model="editsubName"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm12 md6 lg6 pa-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Description</span
                        >
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          placeholder="Description"
                          v-model="editDescription"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm12 md6 lg6 pa-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Features</span
                        >
                        <v-combobox
                          v-model="editFeatures"
                          outlined
                          dense
                          chips
                          clearable
                          multiple
                          placeholder="Features"
                          hide-details="auto"
                        ></v-combobox>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Trial Period (days)</span
                        >
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          placeholder="Trial Period"
                          v-model="editTrialPeriod"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap>
                          <v-flex lg5>
                            <span
                              style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                              "
                              >Is the plan Free</span>
                          </v-flex>
                          <v-flex lg7>
                            <v-checkbox
                              outlined
                              dense
                              v-model="editIsFree"
                              hide-details="auto"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap>
                          <v-flex lg7>
                            <span
                              style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                              "
                              >Is the Access Limited</span
                            >
                          </v-flex>
                          <v-flex lg5>
                            <v-checkbox
                              outlined
                              dense
                              v-model="editIsLimitedAccess"
                              hide-details="auto"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap>
                          <v-flex lg8>
                            <span
                              style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                              "
                              >File Uploading Limited</span
                            >
                          </v-flex>
                          <v-flex lg4>
                            <v-checkbox
                              outlined
                              dense
                              v-model="editIsFileUploadLimited"
                              hide-details="auto"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap>
                          <v-flex lg5>
                            <span
                              style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                              "
                              >Ad Included</span
                            >
                          </v-flex>
                          <v-flex lg7>
                            <v-checkbox
                              outlined
                              dense
                              v-model="editIsAdsIncluded"
                              hide-details="auto"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap>
                          <v-flex lg8>
                            <span
                              style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                              "
                              >File Export Available</span
                            >
                          </v-flex>
                          <v-flex lg4>
                            <v-checkbox
                              outlined
                              dense
                              v-model="editIsFileExportAvailable"
                              hide-details="auto"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap>
                          <v-flex lg8>
                            <span
                              style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                              "
                              >File Register Available</span
                            >
                          </v-flex>
                          <v-flex lg4>
                            <v-checkbox
                              outlined
                              dense
                              v-model="editIsFileRegisterAvailable"
                              hide-details="auto"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          v-if="editIsFileUploadLimited"
                          >File Upload Limit (MB)</span>
                        <v-text-field
                        v-if="editIsFileUploadLimited"
                          outlined
                          dense
                          type="number"
                          placeholder="File Upload Limit"
                          v-model="editFileUploadLimit"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>

                      <v-flex lg6 pa-2>
                        <v-layout wrap justify-center>
                          <v-flex lg6 pr-lg-2>      
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Status</span
                        >
                        <v-select
                          outlined
                          dense
                          :items="['Active', 'Inactive']"
                          item-text="text"
                          item-value="text"
                          placeholder="Select Status"
                          v-model="editsubStatus"
                          hide-details="auto"
                        >
                        <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
                      </v-flex>
                      
                      <v-flex lg6 pl-lg-2>      
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Priority</span
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="editplanPriority"
                          hide-details="auto"
                        >
  </v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>

</v-layout>
                  </v-flex>

                  <v-flex lg12 pt-5 class="text-center">
                    <v-btn color="rgba(0, 160, 142, 1)" @click="editSubscription()">
                      <span
                        style="
                          color: white;
                          font-family: Montserrat-Regular;
                          font-size: 14px;
                          font-weight: 600;
                          text-transform: none;
                        "
                        >Submit</span
                      >
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
    </v-dialog>

    <v-dialog v-model="deleteSub" width="25%" content-class="dialog-custom">
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex lg12>
            <v-layout wrap justify-center>

              <v-flex lg12>
                <span style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    ">
                  Are you sure you want to Delete this Plan?
                </span>
              </v-flex>
              
              <v-flex lg12 pt-5 class="text-center">
                <v-layout wrap justify-center>
                  <v-flex lg6>
                    <v-btn @click="deleteSub = false">
                  <span style="
                        color:rgba(0, 160, 142, 1);
                        font-family: Montserrat-Regular;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: none;
                      ">
                    No
                  </span>
                </v-btn>
                  </v-flex>
                  <v-flex lg6>
                    <v-btn color="rgba(0, 160, 142, 1)" @click="deleteSubscription()">
                  <span style="
                        color: white;
                        font-family: Montserrat-Regular;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: none;
                      ">
                    Yes
                  </span>
                </v-btn>
                  </v-flex>
                </v-layout>
                
              </v-flex>


            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPlan" width="400px" content-class="dialog-custom">
  <v-card>
    <v-layout wrap justify-center pa-8 pt-6>
      <v-flex lg12>
        <v-layout wrap justify-center>
          <v-flex xs11 sm11 md11 lg11 pb-3>
            <span style="
                  font-family: Montserrat-Regular;
                  font-size: 24px;
                  font-weight: 700;
                ">New Plan Type
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1>
            <v-btn icon @click="addPlan = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Name
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-text-field outlined dense type="text" placeholder="Name" v-model="name"
              hide-details="auto"></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Duration
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-text-field outlined dense type="number" placeholder="Duration" v-model="duration"
              hide-details="auto"></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Status
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-select outlined dense :items="statusList" item-text="text" item-value="text"
              placeholder="Status" v-model="status" hide-details="auto"></v-select>
          </v-flex>

          <v-flex xs11 sm11 md11 lg11 pt-3>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Bulk Subscription
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1 pt-3>
            <v-checkbox v-model="isBulkSubscriptionAvailable" hide-details="auto"></v-checkbox>
          </v-flex>

          <v-flex xs11 sm11 md11 lg11 pt-3 v-if="isBulkSubscriptionAvailable">
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Accounts Limited
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1 pt-3 v-if="isBulkSubscriptionAvailable">
            <v-checkbox v-model="isBulkAccountsLimited" hide-details="auto"></v-checkbox>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 pt-3 v-if="isBulkAccountsLimited && isBulkSubscriptionAvailable">
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Max Account Limit
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 pt-1 v-if="isBulkAccountsLimited && isBulkSubscriptionAvailable">
            <v-text-field outlined dense type="number" placeholder="Max Account Limit" v-model="maxAccountLimit"
              hide-details="auto"></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
            <v-btn color="rgba(0, 160, 142, 1)" @click="addSubscriptionPlan()">
              <span style="
                    color: white;
                    font-family: Montserrat-Regular;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: none;
                  ">
                Submit
              </span>
            </v-btn>
          </v-flex>

        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</v-dialog>



    <v-dialog v-model="addPricing" width="400px" content-class="dialog-custom">
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span style="
                      font-family: Montserrat-Regular;
                      font-size: 24px;
                      font-weight: 700;
                    ">Add Pricing
                </span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon>
                  <v-icon @click="clearPricingDialog()">mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs11 sm11 md11 lg11 pt-1>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Is default pricing plan
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1 pt-1>
            <v-checkbox v-model="isDefault" hide-details="auto"></v-checkbox>
          </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
  <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">
    Country
  </span>
</v-flex>
<v-flex xs12 sm12 md12 lg12>
  <v-select
    outlined
    dense
    :items="countries" 
    item-text="name"
    item-value="_id"
    placeholder="Select Country"
    v-model="countryId"
    hide-details="auto"
  ></v-select>
</v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    ">
                  Amount
                </span>
              </v-flex>
              <v-flex lg12>
                <v-text-field outlined dense type="number" placeholder="Amount" v-model="amount"
                  hide-details="auto"></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    ">
                  Original Amount
                </span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense type="number" placeholder="Orginal Amount" v-model="originalAmount"
                  hide-details="auto"></v-text-field>
              </v-flex>

              <v-flex lg12 pt-5 class="text-center">
                <v-btn color="rgba(0, 160, 142, 1)" @click="addSubscriptionPricing()">
                  <span style="
                        color: white;
                        font-family: Montserrat-Regular;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: none;
                      ">
                    Submit
                  </span>
                </v-btn>
              </v-flex>


            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editPlanType" width="400px" content-class="dialog-custom">
  <v-card>
    <v-layout wrap justify-center pa-8 pt-6>
      <v-flex lg12>
        <v-layout wrap justify-center>
          <v-flex xs11 sm11 md11 lg11 pb-3>
            <span style="
                  font-family: Montserrat-Regular;
                  font-size: 24px;
                  font-weight: 700;
                ">Edit Plan
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1>
            <v-btn icon @click="editPlanType = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Name
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-text-field outlined dense type="text" placeholder="Name" v-model="editName"
              hide-details="auto"></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Duration
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-text-field outlined dense type="number" placeholder="Duration" v-model="editDuration"
              hide-details="auto"></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Status
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-select outlined dense :items="statusList" item-text="text" item-value="text"
              placeholder="Select Status" v-model="editStatus" hide-details="auto"></v-select>
          </v-flex>

          <v-flex xs11 sm11 md11 lg11 pt-3>
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Bulk Subscription
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1 pt-3>
            <v-checkbox v-model="editIsBulkSubscriptionAvailable" hide-details="auto"></v-checkbox>
          </v-flex>

          <v-flex xs11 sm11 md11 lg11 pt-3 v-if="editIsBulkSubscriptionAvailable">
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Accounts Limited
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1 pt-3 v-if="editIsBulkSubscriptionAvailable">
            <v-checkbox v-model="editIsBulkAccountsLimited" hide-details="auto"></v-checkbox>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 pt-3 v-if="editIsBulkAccountsLimited && editIsBulkSubscriptionAvailable">
            <span style="
                  font-family: Lato-Regular;
                  font-size: 16px;
                  font-weight: 500;
                ">Max Account Limit
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 v-if="editIsBulkAccountsLimited && editIsBulkSubscriptionAvailable">
            <v-text-field outlined dense type="number" placeholder="Max Account Limit" v-model="editMaxAccountLimit"
              hide-details="auto"></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
            <v-btn color="rgba(0, 160, 142, 1)" @click="editSubscriptionPlan()">
              <span style="
                    color: white;
                    font-family: Montserrat-Regular;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: none;
                  ">
                Submit
              </span>
            </v-btn>
          </v-flex>

        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</v-dialog>


    <v-dialog v-model="editPricing" width="400px" content-class="dialog-custom">
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span style="
                      font-family: Montserrat-Regular;
                      font-size: 24px;
                      font-weight: 700;
                    ">Edit Pricing
                </span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon>
                  <v-icon @click="editPricing = false">mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    ">
                  Orginal Amount
                </span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense type="number" placeholder="Amount" v-model="editOrginalAmount"
                  hide-details="auto"></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <span style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    ">
                  Amount
                </span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense type="number" placeholder="Amount" v-model="editAmount"
                  hide-details="auto"></v-text-field>
              </v-flex>

              

              <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
                <v-btn color="rgba(0, 160, 142, 1)" @click="editSubscriptionPricing()">
                  <span style="
                        color: white;
                        font-family: Montserrat-Regular;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: none;
                      ">
                    Submit
                  </span>
                </v-btn>
              </v-flex>


            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      subView: {},
      subId: this.$route.query.id,
      addPlan: false,
      statusList: ["Active", "Inactive"],
      planType: [],
      amount: "",
      originalAmount: "",
      isDefault: false,
      addPricing: false,
      countries:[],
      planTypeId:"",
      planId: "",
      isPricing: false,
      name:"",
      duration: "",
      status: "",
      countryId:"",
      pricing:[],
      editPlanType: false,
      typeId: "",
      editPricing: false,
      pricingId: "",
      editAmount:"",
      editOrginalAmount: "",
      editIsDefault: false,
      editName:"",
      editDuration:"",
      editStatus:"",
      editSubDialog: false,
      editFeatures: [], 
      editsubName: "", 
      editDescription: "", 
      editIsFree: false, 
      editTrialPeriod: "", 
      editIsLimitedAccess: false, 
      editIsFileUploadLimited: false, 
      editFileUploadLimit: 0, 
      editIsAdsIncluded: false, 
      editIsFileRegisterAvailable: false, 
      editIsFileExportAvailable: false,
      editsubStatus: "Active",
      editStatusList: ["Active", "Inactive"],
      editplanPriority: "",
      deleteSub: false,
      features:[],
      isBulkSubscriptionAvailable: false,
      isBulkAccountsLimited: false,
      maxAccountLimit: "",

      editIsBulkSubscriptionAvailable: false,
      editIsBulkAccountsLimited: false,
      editMaxAccountLimit: "",
      };
  },
  watch: {
    isBulkSubscriptionAvailable(newVal) {
      if (!newVal) {
        // Reset dependent values if Bulk Subscription is unchecked
        this.isBulkAccountsLimited = false;
        this.maxAccountLimit = null;
      }
    },
    isBulkAccountsLimited(newVal) {
      if (!newVal) {
        // Reset maxAccountLimit if Accounts Limited is unchecked
        this.maxAccountLimit = null;
      }
    },

    editIsBulkSubscriptionAvailable(newVal) {
      if (!newVal) {
        // Reset dependent values if Bulk Subscription is unchecked
        this.editIsBulkAccountsLimited = false;
        this.editMaxAccountLimit = null;
      }
    },
    editIsBulkAccountsLimited(newVal) {
      if (!newVal) {
        // Reset maxAccountLimit if Accounts Limited is unchecked
        this.editMaxAccountLimit = null;
      }
    },
  },
  mounted() {
    this.getSubscriptionView();
    this.getSubscriptionPlanView();
    this.getSubscriptionPricing();
    this.getCountry();
  },
  methods: {

    getSubscriptionView() {
      this.appLoading = true;
      axios({
        url: '/subscriptionPlan/view/' + this.subId,

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.subView = response.data.data;
          this.features = response.data.data.features;
          // this.name = response.data.data.name;
          // this.categoryArray = response.data.data.category;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getSubscriptionPlanView() {
      this.appLoading = true;
      axios({
        url: "/subscriptionPlan/type/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          subscriptionPlanId: this.subId,
          // count: this.count,
          // page: this.currentPage,
          // keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.planType = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getSubscriptionPricing() {
      this.appLoading = true;
      axios({
        url: "/subscriptionPlan/pricing/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          subscriptionPlanId: this.planId,
          subscriptionPlanTypeId: this.planTypeId,
          // status: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.pricing = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteSubscription(){
      axios({
        method: "POST",
        url: '/subscriptionPlan/delete/' + this.subId,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$router.push('/subcategory')
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    addSubscriptionPlan() {
      axios({
        method: "POST",
        url: "/subscriptionPlan/type/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          subscriptionPlanId: this.subId,
          name: this.name,
          duration: this.duration,
          status: this.status, // Assuming status is either "Active" or "Inactive"
          isBulkSubscriptionAvailable: this.isBulkSubscriptionAvailable,
          isBulkAccountsLimited: this.isBulkAccountsLimited,
          maxAccountLimit: this.maxAccountLimit,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptionPlanView(); // Assuming this method updates the relevant data
            this.addPlan = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    subscriptionEditPass() {
    console.log("subView:", this.subView);
    this.editSubDialog = true;
    this.editsubName = this.subView.name;
    this.editDescription = this.subView.description;
    this.editFeatures = this.subView.features;
    this.editTrialPeriod = this.subView.trialPeriod;
    this.editIsFree = this.subView.isFree;
    console.log("editIsFree:", this.editIsFree);
    this.editIsLimitedAccess = this.subView.isLimitedAccess;
    this.editIsFileUploadLimited = this.subView.isFileUploadLimited;
    this.editFileUploadLimit = this.subView.fileUploadLimit;
    this.editIsAdsIncluded = this.subView.isAdsIncluded;
    this.editIsFileRegisterAvailable = this.subView.isFileRegisterAvailable;
    this.editIsFileExportAvailable = this.subView.isFileExportAvailable;
    this.editsubStatus = this.subView.status;
    this.editplanPriority = this.subView.planPriority;
  },

  editSubscription(){
            axios({
                method: "POST",
                url: "/subscriptionPlan/edit",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    id: this.subId,
                    name: this.editsubName,
                    description: this.editDescription,
                    features: this.editFeatures, // Assuming this.features is an array of strings
                    isFree: this.editIsFree,
                    trialPeriod: this.editTrialPeriod,
                    isLimitedAccess: this.editIsLimitedAccess,
                    isFileUploadLimited: this.editIsFileUploadLimited,
                    fileUploadLimit: this.editFileUploadLimit,
                    isAdsIncluded: this.editIsAdsIncluded,
                    status: this.editsubStatus,
                    isFileRegisterAvailable: this.editIsFileRegisterAvailable,
                    isFileExportAvailable: this.editIsFileExportAvailable, // Assuming status is either "Active" or "Inactive"
                    planPriority: this.editplanPriority,
                },
            })
                .then((response) => {
                    if (response.data.status) {
                        this.getSubscriptionView(); // Assuming this method updates the relevant data
                        this.editSubDialog = false;
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
  }, 

    editSubscriptionPlan(){
      axios({
        method: "POST",
        url: "/subscriptionPlan/type/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.typeId,
          name: this.editName,
          duration: this.editDuration,
          status: this.editStatus, // Assuming status is either "Active" or "Inactive"
          isBulkSubscriptionAvailable: this.editIsBulkSubscriptionAvailable,
          isBulkAccountsLimited: this.editIsBulkAccountsLimited,
          maxAccountLimit: this.editMaxAccountLimit,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptionPlanView(); // Assuming this method updates the relevant data
            this.editPlanType = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });

    },

    deleteSubscriptionPlan(id) {
      axios({
        method: "POST",
        url: '/subscriptionPlan/type/delete/' + id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptionPlanView();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    planIdPass(ptypeId,pId){
      this.planTypeId = ptypeId;
      this.planId = pId;
      this.isPricing = true;
      this.getSubscriptionPricing();
    },

    addSubscriptionPricing() {
      axios({
        method: "POST",
        url: "/subscriptionPlan/pricing/add ",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          subscriptionPlanId: this.planId,
          subscriptionPlanTypeId: this.planTypeId,
          countryId: this.countryId,
          amount: this.amount,
          originalAmount: this.originalAmount,
          isDefault: this.isDefault,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptionPricing(); // Assuming this method updates the relevant data
            this.clearPricingDialog();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    clearPricingDialog(){
      this.isDefault = null;
      this.countryId = "";
      this.amount = "";
      this.originalAmount = "";
      this.addPricing = false;
    },

    pricingEdit(prid,pAmount,pOriginalAmount,pEditIsDefault){
    this.pricingId = prid;
    this.editAmount =  pAmount;
    this.editOrginalAmount = pOriginalAmount;
    this.editIsDefault = pEditIsDefault
    // this.status =  tStatus;
    this.editPricing = true;
    },

    editSubscriptionPricing() {
      axios({
        method: "POST",
        url: "/subscriptionPlan/pricing/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.pricingId,
          amount: this.editAmount,
          originalAmount: this.editOrginalAmount,
          isDefault: this.editIsDefault,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptionPricing(); // Assuming this method updates the relevant data
            this.editPricing = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteSubscriptionPricing(id){
      axios({
        method: "POST",
        url: '/subscriptionPlan/pricing/delete/' + id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptionPricing();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

  planTypeEdit(tid,tName,tDuration,tStatus,tisBulkSubscriptionAvailable, tisBulkAccountsLimited, tmaxAccountLimit){
    this.typeId = tid;
    this.editName =  tName;
    this.editDuration =  tDuration;
    this.editStatus =  tStatus;
    this.editPlanType = true;
    this.editIsBulkSubscriptionAvailable =  tisBulkSubscriptionAvailable;
    this.editIsBulkAccountsLimited =  tisBulkAccountsLimited;
    this.editMaxAccountLimit = tmaxAccountLimit;
  },

    getCountry() {
        this.appLoading = true;
        axios({
          url: "/country/list",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.countries = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
  },
};
</script>
<style scoped>
.custom-card-size {
    border-radius: 10px;
    height: 155px;
    width: 250px; 
  }

  .custom-card-size-plantype {
    border-radius: 10px;
    height: 200px;
    width: 250px; 
  }

  .v-list-item--active {
  background-color: transparent !important; /* Removes the background */
}
  .dull-card {
  opacity: 0.7; /* Adjust the opacity to make the card appear dull */
  filter: grayscale(100%); /* Optional: convert the card to grayscale */
}
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>